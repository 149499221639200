<template>
<div class="container">
  <p class="tips">！！！提醒：为避免造成数据异常，请在一个任务结束后再触发其他任务，同时请勿短时间内连续触发同一个任务。如有问题请及时联系管理员。</p>
  <div class="btn-wrap">
    <div v-for="item, index in buttonInfo" :key="item.method">
      <Divider plain orientation="left">{{ index+1 }}. {{ item.btnTxt }} <span class="flag" v-if="item.deprecated">（已废弃）</span></Divider>
      <Button type="primary" :loading="item.loading" :disabled="item.deprecated || item.disabled" @click="doSync(item)">{{item.btnTxt}}</Button>
      <div class="desc">*{{item.desc}}</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      buttonInfo: [
        {
          btnTxt: '同步项目constructionProject',
          method: 'syncProjects',
          desc: '该同步按钮触发会将红圈所有项目同步到面板，面板中的数据仅会新增或更新并不会被删除，如需删除请联系管理员。同时新增的项目编号也会被同步到项目资料表。',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步项目设计CustomObject117__c',
          method: 'syncProjectDesign',
          desc: '该同步按钮触发用于将CustomObject117__c中启动设计单时间和审批状态同步到面板',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '回写项目各阶段回款时间',
          method: 'syncWriteBackProjects',
          desc: '该同步按钮触发用于将项目各阶段日期加上偏移量回写到合同款项明细CustomObject645__c.field13__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步区域回款数据CustomObject645__c',
          method: 'syncRegionReceived',
          desc: '该同步按钮触发用于拉取红圈区域回款数据CustomObject645__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步政府补贴申请CustomObject311__c',
          method: 'syncGovSubsidyApply',
          desc: '该同步按钮触发用于拉取红圈政府补贴申请CustomObject311__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步安全评估数据CustomObject1979__c',
          method: 'syncSafetyEvaluation',
          desc: '该同步按钮触发用于拉取红圈安全评估数据CustomObject1979__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步维修工单数据CustomObject1741__c',
          method: 'syncRepairIncident',
          desc: '该同步按钮触发用于拉取红圈同步维修工单数据CustomObject1741__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步项目采购合同constructionPurchaseContract',
          method: 'syncConstructionPurchaseContract',
          desc: '该同步按钮触发用于拉取红圈同步项目采购合同constructionPurchaseContract',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步部门输入CustomObject1842__c',
          method: 'syncDepartmentInput',
          desc: '该同步按钮触发用于拉取红圈部门输入CustomObject1842__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步区域合同签订CustomObject644__c',
          method: 'syncRegionSigned',
          desc: '该同步按钮触发用于拉取红圈区域合同签订CustomObject644__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步安全事故CustomObject1801__c',
          method: 'syncSecurityIncident',
          desc: '该同步按钮触发用于拉取红圈安全事故CustomObject1801__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步前期配套CustomObject1116__c',
          method: 'syncEarlySupport',
          desc: '该同步按钮触发用于拉取红圈前期配套CustomObject1116__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步区域应收明细CustomObject671__c',
          method: 'syncRegionReceivedDetail',
          desc: '该同步按钮触发用于拉取红圈区域应收明细CustomObject671__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步地勘数据CustomObject118__c',
          method: 'syncGeologicalSurvey',
          desc: '该同步按钮触发用于拉取红圈地勘数据CustomObject118__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步房检数据CustomObject119__c',
          method: 'syncHouseInspection',
          desc: '该同步按钮触发用于拉取红圈房检数据CustomObject119__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步报建数据CustomObject211__c',
          method: 'syncReportingConstruction',
          desc: '该同步按钮触发用于拉取红圈报建数据CustomObject211__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步报规数据CustomObject122__c',
          method: 'syncReportingPlanning',
          desc: '该同步按钮触发用于拉取红圈报规数据CustomObject122__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步派单数据CustomObject2111__c',
          method: 'syncDispatchOrderDetail',
          desc: '该同步按钮触发用于拉取红圈派单数据CustomObject2111__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '回写项目全过程CustomObject2146__c',
          method: 'syncWriteBackWholeProcess',
          desc: '该同步按钮触发用于回写项目全过程CustomObject2146__c',
          loading: false,
          disabled: false,
          deprecated: true
        },
        {
          btnTxt: '回写项目编号到项目资料表CustomObject2302__c',
          method: 'syncWriteBackProjectShortName',
          desc: '该同步按钮触发回写项目编号到项目资料表CustomObject2302__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '回写项目检测报告到项目资料表CustomObject2302__c',
          method: 'syncWriteBackTestReport',
          desc: '该同步按钮触发回写项目检测报告到项目资料表CustomObject2302__c',
          loading: false,
          disabled: false,
          deprecated: false
        },
        {
          btnTxt: '同步用户数据User',
          method: 'syncUsers',
          desc: '该同步按钮触发用于拉取红圈用户数据User',
          loading: false,
          disabled: false,
          deprecated: false
        }
      ],
    }
  },
  methods: {
    doSync(item) {
      this.$Message.info("正在同步，请耐心等待...");
      item.loading = true;
      this.buttonInfo.forEach(el => {
        if (!el.loading) {
          el.disabled = true;
        }
      });
      console.log(item.method);
      this.$http.get(`/dashboard/${item.method}`).then(rs => {
        this.buttonInfo.forEach(el => {
          el.disabled = false;
        });
        item.loading = false;
        this.$Message.success("同步成功!");
      }).catch(err => {
        this.buttonInfo.forEach(el => {
          el.disabled = false;
        });
        item.loading = false;
        this.$Message.error("同步失败，请联系管理员。");
      })
    }
  }
}
</script>

<style scoped>
.tips{
  font-size: 16px;
  color: #ed2727;
}
.btn-wrap{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 50px;
}
.desc{
  color: #b7b7b7;
  margin-top: 10px;
}
.flag{
  color: #ed2727;
}
</style>